<template>
  <container id="faq" links="true" color="blue" :title="t('title')" :subtitle="t('subtitle', {email:'info@onearth.be'})">
      <faq-item  v-for="(item, i) in data" :key="`faq-${i}`" :data="item"></faq-item>
  </container>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FaqItem from './FaqItem';
import Container from './Container.vue'

export default {
  components: { Container, FaqItem },
  setup(){

        const {t} = useI18n({
            messages:{
                en: require('../locales/en/faq.json'),
                fr: require('../locales/fr/faq.json')
            },
        })


      const data = computed(() =>  [
          {
              question:t('faq[0].question'),
              answer:[
                  t('faq[0].answer[0]'),
                  t('faq[0].answer[1]')
                ]
          },
          {
              question:t('faq[1].question'),
              answer:[
                   t('faq[1].answer[0]'),
                   t('faq[1].answer[1]')
              ]
          },
          {
              question:t('faq[2].question'),
              answer:[
                   t('faq[2].answer[0]')
              ]
        },
          {
              question:t('faq[3].question'),
              answer:[
                   t('faq[3].answer[0]'),
                   t('faq[3].answer[1]'),
                   t('faq[3].answer[2]'),
                   t('faq[3].answer[3]'),
                   t('faq[3].answer[4]'),
                   t('faq[3].answer[5]')
              ]
        },
          {
              question:t('faq[4].question'),
              answer:[
                   t('faq[4].answer[0]'),
                   t('faq[4].answer[1]')
              ]
            },
          {
              question:t('faq[5].question'),
              answer:[
                        t('faq[5].answer[0]'),
                        t('faq[5].answer[1]')
                ]
          }
      ]);

      return {data, t}
  }

}
</script>

