<template>
    <div class="faq"  @click=" answer = !answer">
          <div class="faq__q">
              <h4>
                {{data.question}}
            </h4>
            <button :class="{'faq__q--answer' : answer}" aria-label="display faq answer">
                <Svg icon="plus" className="faq__q--svg"/>
            </button>
          </div>
          <div v-if="answer" class="faq__a" >
                <p v-for="(text, j) in data.answer" :key="j">
                    {{text}}
                </p>
          </div>
          
      </div>

</template>

<script>

import {ref} from 'vue';
import Svg from './utils/Svg'
export default {
    components:{Svg},
    props:['data', 'i'],
    setup(){
        const answer = ref(false);
        return {answer}
    }
}
</script>

<style lang="scss" scoped>
@import '../style/main';

.faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid currentColor;
    cursor: pointer;
    border-radius: 10px;
     padding: 1.2rem 2rem;

    &:not(:last-child){
        margin-bottom: 2rem;
    }

    @media (hover: hover) {
        &:hover{
            background-color: rgba($color-white, .2);
        }
    }

    

    &__q{
        display: flex;
        align-items: center;
        cursor: pointer;
       
        justify-content: space-between;
        & h4{
            letter-spacing: .1rem;
            text-transform: uppercase;
            font-weight: 400;    
        }
        
        

        & button{
            @include buttonNoStyle;
            transition: all .2s;
           
        }

        &--answer{
            transform: rotate(45deg);
        }

        &--svg{
            @include svg(2.2rem);
        }
    }

    &__a{
        margin-top: 2rem;
        text-align: justify;

        & p{
            margin-bottom: .8rem;
        }
    }
}

</style>